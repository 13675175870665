@import 'src/common/styles/variables';

@mixin flex( $flexDirection, $justifyContent, $alignItems) {
  display: flex;
  display: -webkit-flex;
  flex-direction: $flexDirection;
  -webkit-flex-direction: $flexDirection;
  justify-content: $justifyContent;
  -webkit-justify-content: $justifyContent;
  align-items: $alignItems;
  -webkit-align-items: $alignItems;
}

@mixin text( $fontFamily, $lineHeight, $fontSize, $fontWeight, $color) {
  font-family: $fontFamily, sans-serif;
  line-height: $lineHeight;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  font-style: normal;
}

@mixin hover($delay, $opacity) {
  transition: all $delay, opacity $delay linear;
  -webkit-transition: all $delay, opacity $delay linear;

  &:hover {
    opacity: $opacity;
  }
}

@mixin scale($delay, $scale) {
  transition: all $delay, scale $delay linear;
  -webkit-transition: all $delay, scale $delay linear;

  &:active {
    scale: $scale;
  }
;
}

@mixin scroll($width,$marginBottom, $minHeight, $borderWidth) {
  overflow-y: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    margin-bottom: $marginBottom;
  }

  &::-webkit-scrollbar-thumb {
    min-height: $minHeight;
    border-radius: 20px;
    background-color: $lineColor-l1;
    border: $borderWidth solid rgba(0, 0, 0, 0);
    background-clip: padding-box;

    &:hover {
      background-color: $lineColor-l1;
    }
  }
}
