@import 'src/common/styles/mixins';
@import 'src/common/styles/variables';

.dots {
  position: relative;
  @include flex(column, center, center);
  padding: 0 .7rem;
  cursor: pointer;
  height: 1.5rem;
  border-radius: 50%;
  background-color: transparent;
  box-sizing: border-box;

  &_container {
    opacity: .5;
    @include hover(.1s, 1);
    @include scale(.1s, .8);

    &_dot {
      border: .125rem solid $textColor-t1;
      transform: rotate(90deg);
      border-radius: 2px;
      margin: 1px;
    }
  }

  &_popUp {
    position: absolute;
    width: 8rem;
    background: transparent;
    right: 1.5rem;
    top: -1rem;
    z-index: 11;

    &_box {
      text-align: start;
      border-radius: 0.625rem;
      box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.25);
      background: $mainColor-m1;
      padding: .25rem 2.8rem .2rem 1rem;
      &_child {
        z-index: 25;
      }
    }
  }
}
