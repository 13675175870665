@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.publicLayout {
  width: 100%;
  height: 100vh;
  @include flex(row, flex-start, center);
  box-sizing: border-box;
  background: $mainColor-m4;

  &_logo {
    width: calc(50% - 16rem);
    height: 100vh;
    padding: 1rem;
    margin: 0 8rem;
    @include flex(row, center, center);
    box-sizing: border-box;
  }

  &_child {
    min-width: 50%;
    height: 100vh;
    @include flex(row, center, center);
    background: $backgroundColor-l1;
  }
}
