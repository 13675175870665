@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.searchInput {
  @include flex(row, flex-start, center);
  position: relative;
  width: 22.5rem;
  background: transparent;
  height: 2.5rem;
  border: 1px solid $lineColor-l1;
  border-radius: 0.625rem;
  box-sizing: border-box;

  &_inputWrapper {
    padding-left: 3rem;
    width: 100%;
    height: 100%;
    transition: all 0.15s;
    @include flex(row, flex-start, center);

    &_input {
      width: 100%;
      margin: 0;
      height: 100%;
      @include text($font-l1, $lineHeight-s, $fontSize-xs, $fontWeight-r, $textColor-t1);
      background-color: transparent;
      border: none;
      outline: transparent;
      transition: all 0.15s;

      &::placeholder {
        color: $textColor-t2;
      }
    }

    &_icon {
      position: absolute;
      left: .8rem;
      stroke: $textColor-t2;
    }
  }
}