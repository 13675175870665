@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.textField {
  width: 25.625rem;

  &_box {
    position: relative;
    width: 100%;
    margin-top: .25rem;

    &_icon {
      position: absolute;
      left: 0;
      bottom: 50%;
      transform: translate(50%, 50%);
      width: 24px;
      height: 24px;
      margin-left: 0;
      padding: 0;
      background-color: transparent;
      border: 0;
      outline: 0;
      fill: $textColor-t2;
    }

    &:hover {
      .textField_box_icon, .textField_box_showPassword_icon {
        fill: $textColor-t1;
      }
    }

    &_input {
      @include text($font-l1, $lineHeight-s, $fontSize-s, $fontWeight-r, $textColor-t1);
      box-sizing: border-box;
      width: 100%;
      padding: .75rem 1rem .75rem 3rem;
      background: $fieldColor-f1;
      border: 1px solid transparent;
      border-radius: .8rem;
      outline: 0;
      transition: border .2s;

      &::placeholder {
        color: $textColor-t2;
      }

      &_error {
        border: 1px solid $fieldColor-f3!important;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active{
        -webkit-background-clip: text;
        border: 1px solid $fieldColor-f1;
        box-shadow: inset 0 0 20px 30px $fieldColor-f1;
      }
    }

    &_showPassword {
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 50%;
      transform: translate(-50%, 50%);
      width: 24px;
      height: 24px;
      margin-right: 0;
      padding: 0;
      background-color: transparent;
      border: 0;
      outline: 0;

      &_icon {
        fill: $textColor-t2;
      }
    }
  }

  &_label {
    @include flex(row, flex-start, center);
  }

  &_error {
    @include flex(row, flex-start, center);
    color: $fieldColor-f3!important;
  }
}








