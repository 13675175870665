$mainColor-m1: #FFFFFF;
$mainColor-m2: #5870BB;
$mainColor-m3: #5870BB7F;
$mainColor-m4: #00144A;

$textColor-t1: #0B365B;
$textColor-t2: #0B365B4C;
$textColor-t3: #0B365B7F;

$lineColor-l1: #0B365B33;
$lineColor-l2: #0B365B19;

$backgroundColor-l1: linear-gradient(169deg, #FFD7E7 -11.6%, #E1DDFB 64.62%, #A5D1F7 116.82%);
$backgroundColor-l2: linear-gradient(180deg, #0B365B 0.31%, #5870BB 160.63%);
$backgroundColor-l3: #ECF1FF;
$backgroundColor-l4: #F8F8FC;
$backgroundColor-l5: #AFBCE3;

$fieldColor-f1: rgba(88, 112, 187, 0.10);
$fieldColor-f2: #E46095;
$fieldColor-f3: #D50101;
$fieldColor-f4: #EB5757;
$fieldColor-f5: #90C567;
$fieldColor-f6: #E460957F;
$fieldColor-f7: #E4609533;

// font Family
$font-l1: 'Lato';

// font sizes
$fontSize-xs: .875rem;
$fontSize-s: 1rem;
$fontSize-m: 1.125rem;
$fontSize-l: 1.5rem;
$fontSize-xl: 2.25rem;

// font weights
$fontWeight-r: 400; // regular
$fontWeight-m: 500; // medium
$fontWeight-b: 700; // bold

// line heights
$lineHeight-s: 1.25rem;
$lineHeight-m: 1.875rem;
$lineHeight-l: 2.5rem;
