@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.externalDataCard {
  background: $mainColor-m1;
  padding: 3.125rem;
  border-radius: 1rem;
  box-sizing: border-box;
  max-width: 31.875rem;
}
