@import 'src/common/styles/mixins';
@import 'src/common/styles/variables';

.addStudentModal {
  width: 27.5rem;
  background: $mainColor-m1;
  position: absolute;
  top: 50%;
  z-index: 1;
  cursor: default;
  transform: translateY(-50%);
  border: none;
  border-radius: 0.625rem;
  box-sizing: border-box;
  padding: 2.5rem;
  text-align: center;

  &_h3 {
    font-weight: $fontWeight-b;
  }

  &_csv {
    margin-top: 1rem;
  }

  &_textField {
    margin-top: 1.25rem;
    width: 22.5rem;

    .textField_box_input {
      padding: 1rem 1rem 1rem 3rem;
    }

    .textField_box {
      &:hover {
        .addStudentModal_icon {
          stroke: $textColor-t1;
        }
      }
    }
  }

  &_icon {
    stroke: $textColor-t2;
  }

  &_button {
    width: 22.5rem;
    min-width: auto;
    height: 3.75rem;
    margin-top: 1.8rem;
  }
}
