@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.h1 {
  @include text($font-l1, $lineHeight-l, $fontSize-xl, $fontWeight-b, $textColor-t1);
  margin: 0;
}

.h2 {
  @include text($font-l1, $lineHeight-m, $fontSize-l, $fontWeight-m, $textColor-t1);
  margin: 0;
}

.h3 {
  @include text($font-l1, $lineHeight-s, $fontSize-m, $fontWeight-m, $textColor-t1);
  margin: 0;
}

.p1 {
  @include text($font-l1, $lineHeight-s, $fontSize-s, $fontWeight-m, $textColor-t1);
  margin: 0;
}

.p2 {
  @include text($font-l1, $lineHeight-s, $fontSize-s, $fontWeight-r, $textColor-t1);
  margin: 0;
}

.p3 {
  @include text($font-l1, $lineHeight-s, $fontSize-xs, $fontWeight-r, $textColor-t1);
  margin: 0;
}

/*.btn1 {
  @include text($font-l1, $lineHeight-m, $fontSize-l, $fontWeight-b, $textColor-t1);
  cursor: pointer;
  margin: 0;
}

.btn2 {
  @include text($font-l1, $lineHeight-s, $fontSize-m, $fontWeight-m, $textColor-t1);
  cursor: pointer;
  margin: 0;
}*/

.link1 {
  @include text($font-l1, $lineHeight-s, $fontSize-xs, $fontWeight-r, $fieldColor-f2);
  @include scale(.2s, .95);
  @include hover(.2s, .8);
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid $fieldColor-f2;
  }
}

.link1:visited {
  color: $fieldColor-f2;
}

.link2 {
  @include text($font-l1, $lineHeight-s, $fontSize-s, $fontWeight-r, $textColor-t1);
  @include scale(.2s, .95);
  @include hover(.2s, .8);
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid $textColor-t1;
  }
}

.link2:visited {
  color: $textColor-t1;
}
