@import 'src/common/styles/mixins';
@import 'src/common/styles/variables';

.addUserModal {
  width: 27.5rem;
  background: $mainColor-m1;
  position: absolute;
  top: 50%;
  z-index: 1;
  cursor: default;
  transform: translateY(-50%);
  border: none;
  border-radius: 0.625rem;
  box-sizing: border-box;
  padding: 2.5rem;
  text-align: center;

  &_h3 {
    font-weight: $fontWeight-b;
  }

  &_csv {
    margin-top: 1rem;
  }

  &_textField {
    margin-top: 1.25rem;
    width: 22.5rem;

    .textField_box_input {
      padding: 1rem 1rem 1rem 3rem;
    }

    .textField_box {
      &:hover {
        .addUserModal_icon {
          stroke: $textColor-t1;
        }
      }
    }
  }

  &_icon {
    stroke: $textColor-t2;
  }

  &_button {
    width: 22.5rem;
    min-width: auto;
    height: 3.75rem;
    margin-top: 1.8rem;
  }

  &_delete {
    width: 4rem;
    cursor: pointer;
    @include scale(.2s, .8);
    @include hover(.2s, .8);
  }

  &_tableRow {
    width: 100%;
    @include flex(row, space-evenly, center);
    min-height: 3.75rem;
    border-radius: 0.625rem 0.625rem 0 0;
    border-bottom: 1px solid $lineColor-l2;

    &:last-child {
      border-bottom: none;
    }

    &_p2 {
      width: calc(100% / 5); // 5 - column count
      text-align: center;
      word-break: break-word;
      padding: .2rem 1rem;

      &:nth-child(-n + 3) {
        cursor: pointer;
      }
    }
  }
}
