@import 'common/styles/mixins';
@import 'common/styles/variables';

.selectForm {
  position: relative;
  box-sizing: border-box;

  &_label {
    @include text($font-l1, $lineHeight-s, $fontSize-s, $fontWeight-r, $textColor-t3);
  }

  .select {
    &__control {
      @include text($font-l1, $lineHeight-s, $fontSize-s, $fontWeight-r, $textColor-t1);
      background-color: $mainColor-m1;
      border: 1px solid $lineColor-l1;
      border-radius: 10px;
      cursor: pointer;
      min-height: 58px;
      box-shadow: none;
      margin-top: .25rem;

      &:hover {
        border: 1px solid $lineColor-l1;
      }

      &--menu-is-open {
        border: 1px solid $lineColor-l1;
      }
    }

    &__placeholder {
      @include text($font-l1, $lineHeight-s, $fontSize-xs, $fontWeight-r, $textColor-t2);
    }

    &__menu {
      border: none;
      z-index: 5;
    }

    &__menu-list {
      border: none;
      @include scroll(1.1rem, 0, 8rem, .45rem);
    }

    &__single-value {
      color: $textColor-t1;
    }

    &__indicator-separator {
      display: none;
    }

    &__option {
      @include text($font-l1, $lineHeight-s, $fontSize-s, $fontWeight-r, $textColor-t1);
      cursor: pointer;
      background-color: transparent;

      &:hover {
        background-color: transparent;
        border-radius: 10px;
      }

      &:focus {
        border: 1px solid $lineColor-l1;
      }

      &--is-active {
        background-color: transparent;
        border: 1px solid $lineColor-l1;
      }
    }
  }

  &_error .select {
    &__control {
      border: 1px solid $fieldColor-f3;
    }
  }

  &_search {
    @include flex(row, flex-start, center);
    column-gap: 1rem;
  }

  &_error {
    @include text($font-l1, $lineHeight-s, $fontSize-xs, $fontWeight-r, $fieldColor-f3);
  }

  &_option {
    @include flex(row, space-between, center);
    width: 100%;
  }

  &_controlTitle {
    margin-left: 1rem;
  }
}
