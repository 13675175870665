@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

@mixin button {
  @include flex(row, center, center);
  @include text($font-l1, $lineHeight-m, $fontSize-l, $fontWeight-b, $mainColor-m1);
  @include scale(.2s, .95);
  @include hover(.2s, .8);
  min-width: 25.625rem;
  height: 3.375rem;
  outline: transparent;
  cursor: pointer;
  border-radius: 0.625rem;
  border: 1px solid $mainColor-m2;
  background-color: $mainColor-m2;
  -webkit-tap-highlight-color: transparent;
  transition: all .3s, background-color, color 0.3s linear;

  &:hover {
    background-color: $mainColor-m3;
    color: $textColor-t1;
  }

  &:active {
    background-color: $mainColor-m1;
    color: $mainColor-m2;
    border: 1px solid $mainColor-m2;
  }

  &:disabled {
    @include scale(0, 1);
    @include hover(0, 1);
    background-color: $mainColor-m1;
    color: $mainColor-m2;
    cursor: default;
  }
}

.btn1 {
  @include button;
}

.btn2 {
  @include button;
  @include text($font-l1, $lineHeight-s, $fontSize-m, $fontWeight-m, $mainColor-m1);
  background-color: $fieldColor-f2;
  min-width: 15.9375rem;
  height: 3.125rem;
  border: 1px solid transparent;

  &:hover {
    background-color: $fieldColor-f6;
    color: $textColor-t1;
  }

  &:active {
    background-color: $fieldColor-f7;
    color: $textColor-t1;
  }
}

.btn3 {
  @include button;
  @include text($font-l1, $lineHeight-s, $fontSize-m, $fontWeight-m, $textColor-t1);
  background-color: $mainColor-m1;
  border: 1px solid $mainColor-m2;
  min-width: 15.9375rem;
  height: 3.125rem;

  &:hover {
    background-color: $backgroundColor-l5;
    color: $textColor-t1;
    border: 1px solid $mainColor-m2;
  }

  &:active {
    background-color: $backgroundColor-l3;
    color: $textColor-t1;
    border: 1px solid $mainColor-m2;
  }
}