@import 'src/common/styles/variables';
@import 'src/common/styles/mixins';

.sidebar {
  width: 15.625rem;
  min-width: 15.625rem;
  height: 100vh;
  background: $mainColor-m4;;
  @include flex(column, flex-start, flex-start);

  &_logo {
    margin: 3.2rem auto 4.85rem;
    width: 11.125rem;
  }

  &_item {
    width: 100%;
    height: 2.5rem;
    @include flex(row, flex-start, center);
    text-decoration: none;

    &:last-child {
      cursor: pointer;
      margin-left: 2.8rem;
    }

    &_box {
      @include flex(row, space-between, center);
      width: 100%;
      height: 2.5rem;
      text-decoration: none;

      &_row {
        @include flex(row, center, center);
        margin-left: 2.8rem;

        &_link {
          width: 7.5rem;
          margin-left: 1.25rem;
          text-decoration: none;
          color: $mainColor-m1;
          opacity: .7;
        }
      }
    }
  }
}

.activeLink {
  background: $backgroundColor-l1;

  &:after {
    height: 100%;
    content: '';
    color: $mainColor-m2;
    border-right: 4px solid $mainColor-m2;
  }
}
.activeLinkColor {
  color: $textColor-t1;
}
