@import 'src/common/styles/mixins';

.box {
  .container {
    position: fixed;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.6;
    z-index: 20;
    cursor: pointer;
  }
}

.box {
  position: fixed;
  top: 50%;
  left: 50%;
  @include flex(column, center, center);
  z-index: 21;
}